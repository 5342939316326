<template>
  <div>
    <v-select
      :items="item.Options"
      v-model="item.Value"
      :label="$t('Select')"
      single-line
      menu-props="bottom"
      item-value="Id"
      item-text="Value"
      :rules="$validation.getValidation(item.FieldValidationFuntion)"
      validate-on-blur
      :clearable="!item.Required"
      :disabled="
        $helpers.itemNeedsDisable(item.ReadOnly, needsDisable, editItemAdmin)
      "
      dense
      outlined
      hide-details="auto"
    ></v-select>
    <template v-if="$store.getters.editMode">
      <hr class="mt-5" />
      <h4 class="mt-5">{{ $t("Modify select options") }}</h4>

      <v-chip
        v-for="(option, optionIndex) in item.Options"
        :key="optionIndex"
        class="mr-1 mt-1"
        close
        @click="removeOption(optionIndex)"
      >
        {{ option.Value }}
      </v-chip>
      <br />
      <v-row no-gutters class="my-6">
        <v-col
          cols="12"
          :lg="
            item.colWidth < 7
              ? item.colWidth < 5
                ? 12
                : 4
              : item.colWidth < 10
              ? 4
              : 2
          "
        >
          <v-text-field
            :label="$t('Option name')"
            v-model="newOptionName"
            required
            :error="addOptionFieldHasError"
            :rules="addOptionFieldRule"
            class="mr-xs-0 mr-sm-3"
            outlined
            dense
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="3">
          <v-btn
            depressed
            class="mr-3 float-left white--text edit-page-action-button dropdown-action-button"
            color="primarygreen"
            @click="addOption"
          >
            {{ $t("Add option") }}
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters class="my-5">
        <v-col cols="12" sm="12" lg="12">
          <v-row no-gutters>
            <v-col cols="12">
              <v-layout column>
                <v-text-field
                  v-model.number="item.colWidth"
                  hide-details="auto"
                  type="number"
                  max="12"
                  min="1"
                  :label="$t('Column width')"
                  class="mw-w"
                  outlined
                  dense
                ></v-text-field>
                <v-switch
                  flat
                  :ripple="false"
                  v-model="item.Required"
                  class="float-left mr-3"
                  hide-details="auto"
                  :label="$t('Field is required')"
                ></v-switch>
                <v-switch
                  flat
                  :ripple="false"
                  v-model="item.ReadOnly"
                  hide-details="auto"
                  class="float-left mr-3"
                  :label="$t('Read-only')"
                ></v-switch>
                <v-switch
                  flat
                  :ripple="false"
                  v-model="item.RemoveCard"
                  hide-details="auto"
                  class="float-left mr-3"
                  :label="$t('Remove frame')"
                ></v-switch>
              </v-layout>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: [Array, Object],
    },
    needsDisable: {
      type: Boolean,
    },
    editItemAdmin: {
      type: Boolean,
    },
  },
  data() {
    return {
      itemLanguages: this.getItemLanguages(),
      itemDefaultLanguage: this.getItemDefaultLanguage(),
      newOptionName: "",
      addOptionFieldHasError: false,
      addOptionFieldRule: [],
    };
  },

  methods: {
    getItemDefaultLanguage() {
      return this.$config.contentLanguages[0];
    },
    getItemLanguages() {
      return this.$config.contentLanguages;
    },
    removeOption(index) {
      this.item.Options.splice(index, 1);
    },
    addOption() {
      this.addOptionFieldHasError = false;
      this.addOptionFieldRule = [];

      if (this.newOptionName.length > 1) {
        this.item.Options.push({
          Id: this.item.Options.length + 1,
          Value: this.newOptionName,
        });
      } else {
        this.addOptionFieldRule = [
          () => this.$i18n.translate("Minimum required characters is") + " 2",
        ];
        this.addOptionFieldHasError = true;
      }
    },
  },
};
</script>

<style scoped>
.mw-w {
  min-width: 2rem;
  max-width: 8rem;
}
</style>