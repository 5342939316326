var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-select',{attrs:{"items":_vm.item.Options,"label":_vm.$t('Select'),"single-line":"","menu-props":"bottom","item-value":"Id","item-text":"Value","rules":_vm.$validation.getValidation(_vm.item.FieldValidationFuntion),"validate-on-blur":"","clearable":!_vm.item.Required,"disabled":_vm.$helpers.itemNeedsDisable(_vm.item.ReadOnly, _vm.needsDisable, _vm.editItemAdmin),"dense":"","outlined":"","hide-details":"auto"},model:{value:(_vm.item.Value),callback:function ($$v) {_vm.$set(_vm.item, "Value", $$v)},expression:"item.Value"}}),(_vm.$store.getters.editMode)?[_c('hr',{staticClass:"mt-5"}),_c('h4',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.$t("Modify select options")))]),_vm._l((_vm.item.Options),function(option,optionIndex){return _c('v-chip',{key:optionIndex,staticClass:"mr-1 mt-1",attrs:{"close":""},on:{"click":function($event){return _vm.removeOption(optionIndex)}}},[_vm._v(" "+_vm._s(option.Value)+" ")])}),_c('br'),_c('v-row',{staticClass:"my-6",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","lg":_vm.item.colWidth < 7
            ? _vm.item.colWidth < 5
              ? 12
              : 4
            : _vm.item.colWidth < 10
            ? 4
            : 2}},[_c('v-text-field',{staticClass:"mr-xs-0 mr-sm-3",attrs:{"label":_vm.$t('Option name'),"required":"","error":_vm.addOptionFieldHasError,"rules":_vm.addOptionFieldRule,"outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.newOptionName),callback:function ($$v) {_vm.newOptionName=$$v},expression:"newOptionName"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('v-btn',{staticClass:"mr-3 float-left white--text edit-page-action-button dropdown-action-button",attrs:{"depressed":"","color":"primarygreen"},on:{"click":_vm.addOption}},[_vm._v(" "+_vm._s(_vm.$t("Add option"))+" "),_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1),_c('v-row',{staticClass:"my-5",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-layout',{attrs:{"column":""}},[_c('v-text-field',{staticClass:"mw-w",attrs:{"hide-details":"auto","type":"number","max":"12","min":"1","label":_vm.$t('Column width'),"outlined":"","dense":""},model:{value:(_vm.item.colWidth),callback:function ($$v) {_vm.$set(_vm.item, "colWidth", _vm._n($$v))},expression:"item.colWidth"}}),_c('v-switch',{staticClass:"float-left mr-3",attrs:{"flat":"","ripple":false,"hide-details":"auto","label":_vm.$t('Field is required')},model:{value:(_vm.item.Required),callback:function ($$v) {_vm.$set(_vm.item, "Required", $$v)},expression:"item.Required"}}),_c('v-switch',{staticClass:"float-left mr-3",attrs:{"flat":"","ripple":false,"hide-details":"auto","label":_vm.$t('Read-only')},model:{value:(_vm.item.ReadOnly),callback:function ($$v) {_vm.$set(_vm.item, "ReadOnly", $$v)},expression:"item.ReadOnly"}}),_c('v-switch',{staticClass:"float-left mr-3",attrs:{"flat":"","ripple":false,"hide-details":"auto","label":_vm.$t('Remove frame')},model:{value:(_vm.item.RemoveCard),callback:function ($$v) {_vm.$set(_vm.item, "RemoveCard", $$v)},expression:"item.RemoveCard"}})],1)],1)],1)],1)],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }